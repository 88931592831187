import { ACCOUNT_INFO, DASHBOARD, LOGIN, REELS, SPONSORS, TIPS, TRANSACTIONS } from "./page-urls";

import Login from "../pages/Login/Login";
import ActiveSponsors from "../pages/dashboard/ActiveSponsors";
import Dashboard from "../pages/dashboard/Dashboard";
import Transactions from "../pages/dashboard/Transactions/Transactions";
import AccountInfo from "../pages/dashboard/Transactions/AdminAccountInfo/AccountInfo";
import Tips from "../pages/Tips/Tips";
import Reels from "../pages/Reels/Reels";

export const PUBLIC_ROUTES = [
  {
    name: "Login",
    url: LOGIN,
    component: Login
  }
];

export const PRIVATE_ROUTES = [
  {
    name: "dashboard",
    url: DASHBOARD,
    component: Dashboard
  },
  {
    name: "active-sponsors",
    url: SPONSORS,
    component: ActiveSponsors
  },
  {
    name: "transactions",
    url: TRANSACTIONS,
    component: Transactions
  },
  {
    name: "accountInfo",
    url: ACCOUNT_INFO,
    component: AccountInfo
  },
  {
    name: "tips",
    url: TIPS,
    component: Tips
  },
  {
    name: "reels",
    url: REELS,
    component: Reels
  }
];
