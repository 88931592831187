import React, { useState, useEffect } from "react";
import AddTips from "../../components/tips/AddTips";
import { getRequest } from "../../utils/axios-methods";
import { toast } from "react-toastify";
import "./Tips.css";

const Tips = () => {
  const [tips, setTips] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchTips = async () => {
      try {
        const res = await getRequest("/tip");
        setTips(res?.result);
      } catch (error) {
        console.error("Error fetching tips:", error);
        toast.error("Something went wrong!!");
      }
    };
    fetchTips();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage("");
  };

  return (
    <div className="tips-container">
      <header className="tips-header">
        <h2 className="tips-title">Tips and Tricks</h2>
        <button className="add-tips-button" onClick={() => setShowForm(!showForm)}>
          {showForm ? "Hide Form" : "Add New Tip"}
        </button>
      </header>

      {showForm && <AddTips setTips={setTips} />}

      <div className="tips-list">
        {tips.map((tip) => (
          <div className="tip-card" key={tip?._id}>
            <div className="tip-details">
              <h3 className="tip-category">{tip?.category?.toUpperCase()}</h3>
              <p className="tip-description">{tip?.description}</p>
              <p className="tip-sport-type">Sports: {tip?.sportType.join(", ")}</p>
            </div>
            {tip?.file && (
              <div className="tip-image">
                <img
                  src={process.env.REACT_APP_API_URL + "/" + tip?.file}
                  alt={tip?.description}
                  onClick={() => handleImageClick(process.env.REACT_APP_API_URL + "/" + tip?.file)}
                  className="clickable-image"
                />
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="image-modal">
          <button className="close-modal" onClick={closeModal}>
            &times;
          </button>
          <img
            src={selectedImage}
            alt="Full view"
            className="modal-image"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default Tips;
