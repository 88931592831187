// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  max-width: 500px;
  margin: auto;
  padding: 30px;
  background: linear-gradient(to right, #f0f4f8, #e2e8f0);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
  animation: showForm 1s ease-in-out;
}

@keyframes showForm {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

h2 {
  color: #343a40;
}

.form-input {
  border-radius: 5px;
  border: 1px solid #ced4da;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.text-danger {
  font-weight: bold;
}

.icon {
  color: #007bff;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/reels/AddReels.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,uDAAuD;EACvD,mBAAmB;EACnB,yCAAyC;EACzC,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,0CAA0C;AAC5C;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,iDAAiD;AACnD;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".form-container {\n  max-width: 500px;\n  margin: auto;\n  padding: 30px;\n  background: linear-gradient(to right, #f0f4f8, #e2e8f0);\n  border-radius: 10px;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n  border: 1px solid #e0e0e0;\n  animation: showForm 1s ease-in-out;\n}\n\n@keyframes showForm {\n  0% {\n    transform: scale(0);\n  }\n  30% {\n    transform: scale(1.2);\n  }\n  50% {\n    transform: scale(0.9);\n  }\n  70% {\n    transform: scale(1.05);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n\nh2 {\n  color: #343a40;\n}\n\n.form-input {\n  border-radius: 5px;\n  border: 1px solid #ced4da;\n  transition: border-color 0.3s;\n}\n\n.form-input:focus {\n  border-color: #007bff;\n  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);\n}\n\n.btn-primary {\n  background-color: #007bff;\n  border-color: #007bff;\n  border-radius: 5px;\n  transition: background-color 0.3s, transform 0.2s;\n}\n\n.btn-primary:hover {\n  background-color: #0056b3;\n  transform: translateY(-2px);\n}\n\n.text-danger {\n  font-weight: bold;\n}\n\n.icon {\n  color: #007bff;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
