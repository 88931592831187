import { SPONSORS, SPONSORS_PENDING, TRANSACTIONS, TIPS, REELS } from "../../../routes/page-urls";

const menuItems = [
  {
    title: "Sponsors",
    subMenus: [
      {
        subTitle: "Active",
        subLink: SPONSORS
      },
      {
        subTitle: "Request Pending",
        subLink: SPONSORS_PENDING
      }
    ]
  },
  {
    title: "Transactions",
    link: TRANSACTIONS
  },
  {
    title: "Tips",
    link: TIPS
  },
  {
    title: "Reels",
    link: REELS
  }
];
export default menuItems;
