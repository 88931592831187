import React, { useState, useEffect } from "react";
import AddReels from "../../components/reels/AddReels";
import { getRequest } from "../../utils/axios-methods";
import { toast } from "react-toastify";
import "./Reels.css";

const Reels = () => {
  const [reels, setReels] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchReels = async () => {
      try {
        const res = await getRequest("/reel/fetch-reels");
        setReels(res?.reels);
      } catch (error) {
        console.error("Error fetching tips:", error);
        toast.error("Something went wrong!!");
      }
    };
    fetchReels();
  }, []);

  return (
    <section className="reels-container">
      <header className="reels-header">
        <h2 className="reels-title">Reels</h2>
        <button className="add-reels-button" onClick={() => setShowForm(!showForm)}>
          {showForm ? "Hide Form" : "Add New Reel"}
        </button>
      </header>

      {showForm && <AddReels setReels={setReels} />}

      <div className="reels-list">
        {reels.map((reel) => (
          <div className="reel-card" key={reel?._id}>
            <div className="reel-details">
              <h3 className="reel-text">{reel?.text?.toUpperCase()}</h3>
              <p className="reel-tag">Tags: {reel?.tags.join(", ")}</p>
            </div>
            {reel?.file?.file && (
              <div className="reel-video">
                <video
                  src={process.env.REACT_APP_API_URL + "/" + reel.file.file}
                  controls
                  className="reel-player"></video>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Reels;
