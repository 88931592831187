import React, { useState, useRef, useMemo } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaUpload, FaEdit, FaListAlt } from "react-icons/fa";
import { postRequest } from "../../utils/axios-methods";
import "./AddReels.css";

const AddReels = ({ setReels }) => {
  const [text, setText] = useState("");
  const [tag, setTag] = useState("");
  const [file, setFile] = useState(null);

  const [textError, setTextError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [fileError, setFileError] = useState(false);

  const fileInputRef = useRef(null);

  // Memoize the video preview URL
  const videoPreview = useMemo(() => (file ? URL.createObjectURL(file) : null), [file]);
  const handleTextChange = (e) => {
    setText(e.target.value);
    setTextError(!e.target.value);
  };

  const handleTagsChange = (e) => {
    setTag(e.target.value);
    setTagsError(!e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const isValidVideo = selectedFile.type.startsWith("video/");
      const isValidSize = selectedFile.size <= 10 * 1024 * 1024;
      if (isValidVideo && isValidSize) {
        setFile(selectedFile);
        setFileError(false);
      } else {
        setFileError(true);
        if (!isValidVideo) toast.error("Please upload a valid video file.");
        if (!isValidSize) toast.error(`Reel size must be less than ${10} MB.`);
      }
    } else {
      setFileError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTextError(!text);
    setTagsError(!tag);
    setFileError(!file);

    if (text && file && tag) {
      const tags = tag
        .trim()
        .split(" ")
        .map((tagEl) => tagEl.trim());

      const formData = new FormData();
      formData.append("text", text);
      formData.append("tags", tags);
      formData.append("file", file);

      try {
        const res = await postRequest("/reel/create-reel", formData);
        setReels((prevReels) => [res?.data, ...prevReels]);

        toast.success(`Reel added successfully!`);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!!");
      }
    } else {
      toast.error("Please fill out all required fields.");
    }
  };

  const resetForm = () => {
    setText("");
    setTag("");
    setFile(null);

    setTextError(false);
    setTagsError(false);
    setFileError(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="form-container">
      <h2 className="text-center mb-4">Add Reels</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FaEdit className="icon" /> Description
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                value={text}
                onChange={handleTextChange}
                isInvalid={textError}
                className="form-input"
              />
              {textError && <Form.Text className="text-danger">Description is required.</Form.Text>}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FaListAlt className="icon" /> Tags
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tags"
                value={tag}
                onChange={handleTagsChange}
                isInvalid={tagsError}
                className="form-input"
              />
              {tagsError && <Form.Text className="text-danger">tags are required.</Form.Text>}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FaUpload className="icon" /> Upload Reel
              </Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                onChange={handleFileChange}
                isInvalid={fileError}
                className="form-input"
                ref={fileInputRef}
              />
              {fileError && (
                <Form.Text className="text-danger">An reel file is required.</Form.Text>
              )}
              {file && (
                <div className="mt-3">
                  <p className="mt-1">
                    Selected file:
                    {file?.name?.length > 20
                      ? `${file.name.slice(0, 17)}...${file.name.slice(file.name.lastIndexOf("."))}`
                      : file?.name}
                  </p>
                  <video
                    controls
                    src={videoPreview}
                    className="video-preview"
                    style={{ width: "100%", maxHeight: "300px" }}
                  />
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="w-100">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default AddReels;
