import React, { useState, useRef, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { FaUpload, FaEdit, FaListAlt } from "react-icons/fa";
import { postRequest } from "../../utils/axios-methods";
import "./AddTips.css";
import { ALL_SPORTS_COMBINED } from "../../utils/constants";

const AddTips = ({ setTips }) => {
  const [category, setCategory] = useState("trick");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [sportType, setSportType] = useState("");

  const [categoryError, setCategoryError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [sportTypeError, setSportTypeError] = useState(false);

  const fileInputRef = useRef(null);
  const imagesPreview = useMemo(() => (file ? URL.createObjectURL(file) : null), [file]);
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setCategoryError(!e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError(!e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
      setFileError(false);
    } else {
      setFileError(true);
    }
  };

  const handleSportsTypeChange = (e) => {
    setSportType((prevState) =>
      !prevState.includes(e?.target?.value) ? [...prevState, e.target.value] : prevState
    );
    setSportTypeError(!e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCategoryError(!category);
    setDescriptionError(!description);
    setFileError(!file);
    setSportTypeError(!sportType);

    if (category && description && file && sportType) {
      const formData = new FormData();
      formData.append("description", description);
      formData.append("category", category);
      formData.append("file", file);
      formData.append("sportType", sportType);
      try {
        const res = await postRequest("/tip", formData);
        setTips((prevTips) => [res?.result, ...prevTips]);
        toast.success(`${category} added successfully!`);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!!");
      }
    } else {
      toast.error("Please fill out all required fields.");
    }
  };

  const resetForm = () => {
    setCategory("trick");
    setDescription("");
    setFile(null);
    setSportType("");
    setCategoryError(false);
    setDescriptionError(false);
    setFileError(false);
    setSportTypeError(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="form-container">
      <h2 className="text-center mb-4">Add Tips</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FaListAlt className="icon" /> Category
              </Form.Label>
              <Form.Select
                aria-label="Category"
                value={category}
                onChange={handleCategoryChange}
                isInvalid={categoryError}>
                <option value="trick">Trick</option>
                <option value="tip">Tip</option>
              </Form.Select>
              {categoryError && (
                <Form.Text className="text-danger">Category is required.</Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FaEdit className="icon" /> Description
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                value={description}
                onChange={handleDescriptionChange}
                isInvalid={descriptionError}
                className="form-input"
              />
              {descriptionError && (
                <Form.Text className="text-danger">Description is required.</Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>Sports Type</Form.Label>
              <Form.Select
                aria-label="Sports Type"
                value={sportType.length > 0 ? sportType[sportType.length - 1] : sportType}
                onChange={handleSportsTypeChange}
                isInvalid={sportTypeError}>
                {ALL_SPORTS_COMBINED.map((sport) => (
                  <option key={sport.value} value={sport.value}>
                    {sport.label}
                  </option>
                ))}
              </Form.Select>
              {sportTypeError && (
                <Form.Text className="text-danger">Sports type is required.</Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FaUpload className="icon" /> Upload Image
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                isInvalid={fileError}
                className="form-input"
                ref={fileInputRef}
              />
              {fileError && (
                <Form.Text className="text-danger">An image file is required.</Form.Text>
              )}
              {file && (
                <div className="">
                  <p className="mt-1">
                    Selected file:
                    {file?.name?.length > 20
                      ? `${file.name.slice(0, 17)}...${file.name.slice(file.name.lastIndexOf("."))}`
                      : file?.name}
                  </p>
                  <img
                    src={imagesPreview}
                    className="image-preview"
                    style={{ width: "100%", maxHeight: "300px" }}
                  />
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="w-100">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default AddTips;
