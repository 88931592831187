export const API_URL = process.env.REACT_APP_API_URL || "https://api.spoflex.com";

export const COMMON_STATUS = {
  pending: "pending",
  completed: "completed",
  topUp: "top-up",
  withdraw: "withdraw",
  transfer: "transfer",
  reject: "reject",
  approve: "approve"
};

// sports types
export const SPORTS_TYPES = {
  physicalSports: "physicalSports",
  eSports: "eSports"
};

// sports type list
export const SPORTS_TYPES_LIST = [
  {
    label: "Physical Sports",
    value: SPORTS_TYPES.physicalSports
  },
  {
    label: "E-Sports",
    value: SPORTS_TYPES.eSports
  }
];

// type of match
export const GAMES_MODES = {
  single: "single",
  multi: "multi",
  knockOut: "knockout",
  ko: "ko",
  league: "league",
  schedule: "schedule",
  doubleElimination: "doubleelimination"
};

// physical sports list
export const PHYSICAL_SPORTS_LIST = [
  {
    label: "Cricket",
    value: "cricket",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Football",
    value: "football",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Badminton",
    value: "badminton",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Basketball",
    value: "basketball",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Volleyball",
    value: "volleyball",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  }
];

// e-sports list
export const E_SPORTS_LIST = [
  {
    label: "PUB G",
    value: "pubg",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "FreeFire",
    value: "freeFire",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "Fortnite",
    value: "fortnite",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "CallOfDuty",
    value: "callOfDuty",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "Valorant",
    value: "valorant",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "TEKKEN 7",
    value: "tekken_7",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "Counter Strike",
    value: "counterStrike",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "DOTA 2",
    value: "dota_2",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "League of Legends",
    value: "leagueOfLegends",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  }
];

// all sports combined
export const ALL_SPORTS_COMBINED = [...PHYSICAL_SPORTS_LIST, ...E_SPORTS_LIST];

// TOURNAMENT TYPES
export const TOURNAMENT_TYPES = {
  league: "league",
  knockOut: "knockout",
  doubleElimination: "doubleElimination"
};
// tournament types
export const TOURNAMENT_TYPES_LIST = [
  {
    label: "League",
    value: TOURNAMENT_TYPES.league
  },
  {
    label: "knock out",
    value: TOURNAMENT_TYPES.knockOut
  },
  {
    label: "Double Elimination",
    value: TOURNAMENT_TYPES.doubleElimination
  }
];
